import React, {useRef, useEffect} from 'react';
import {useLocation, Switch} from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';

// Images
import circle from './assets/images/circle.png'
import circle2 from './assets/images/circle2.png'

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = page => {
    ReactGA.set({page});
    ReactGA.pageview(page);
};

const projects = [
    {
        img: circle, url: "/projects/circle", brief: "Puzzle",
        title: "The Circle",
        description: "\"The Circle\" is a visually dazzling adventure, " +
            "full of kinetic excitement, but it retreats to formula " +
            "just when it's getting interesting."
    },
    {
        img: circle2, url: "/projects/circle2", brief: "Puzzle",
        title: "The Circle 2.0: In Pursuit Of Red Arrow",
        description:
            "The project builds on the established context of the first game. " +
            "Although beautiful, The Circle leaves a few open questions. " +
            "In Pursuit Of Red Arrow successfully answers them and adds its own."
    },
];

const App = () => {

    const childRef = useRef();
    let location = useLocation();

    useEffect(() => {
        const page = location.pathname;
        document.body.classList.add('is-loaded')
        childRef.current.init();
        trackPage(page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <ScrollReveal
            ref={childRef}
            children={() => (
                <Switch>
                    <AppRoute exact path="/" component={Home} layout={LayoutDefault}
                              componentProps={{projects: projects}}/>
                </Switch>
            )}/>
    );
};

export default App;