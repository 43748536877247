import React from 'react';
import classNames from 'classnames';
import {SectionSplitProps} from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
    ...SectionSplitProps.types
}

const defaultProps = {
    ...SectionSplitProps.defaults,
    //projects: []
}

const FeaturesSplit = ({
                           className,
                           topOuterDivider,
                           bottomOuterDivider,
                           topDivider,
                           bottomDivider,
                           hasBgColor,
                           invertColor,
                           invertMobile,
                           invertDesktop,
                           alignTop,
                           imageFill,
                           projects,
                           ...props
                       }) => {

    const outerClasses = classNames(
        'features-split section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'features-split-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
        'split-wrap',
        invertMobile && 'invert-mobile',
        invertDesktop && 'invert-desktop',
        alignTop && 'align-top'
    );

    const sectionHeader = {
        title: 'Projects',
        paragraph: ''
    };

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content"/>
                    <div className={splitClasses}>

                        {projects.map((e) =>
                            <div className="split-item">
                                <div className="split-item-content center-content-mobile reveal-from-left"
                                     data-reveal-container=".split-item">
                                    <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                                        {e.brief}
                                    </div>
                                    <h3 className="mt-0 mb-12">
                                        {e.title}
                                    </h3>
                                    <p className="m-0">
                                        {e.description}
                                    </p>
                                </div>
                                <div className={
                                    classNames(
                                        'split-item-image center-content-mobile reveal-from-bottom',
                                        imageFill && 'split-item-image-fill'
                                    )}
                                     data-reveal-container=".split-item">
                                    <a href={e.url}>
                                        <Image
                                            src={e.img}
                                            alt="Preview"
                                            width={528}
                                            height={396}/>
                                    </a>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </section>
    );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;