import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesSplit from '../components/sections/FeaturesSplit';

const Home = ({projects}) => {
    return (
        <>
            <Hero className="illustration-section-01"/>
            <FeaturesSplit projects={projects} invertMobile topDivider imageFill className="illustration-section-02"/>
        </>
    );
}

Home.defaultProps = {
    //projects: []
}

export default Home;