import React from 'react';
import classNames from 'classnames';

const defaultProps = {
    iconWidth: 16,
    iconHeight: 16,
};

const FooterSocial = ({
                          className,
                          ...props
                      }) => {

    const classes = classNames(
        'footer-social',
        className
    );

    return (
        <div
            {...props}
            className={classes}
        >
            <ul className="list-reset">
                <li>
                    <a href="https://telegram.me/stadd">
                        <svg
                            width={props.iconWidth}
                            height={props.iconHeight}
                            viewBox="0 0 48 48"
                            xmlns="http://www.w3.org/2000/svg">
                            <title>Telegram</title>
                            <path
                                d="M41.23 8.61a1.87 1.87 0 0 0-1.34-.126c-.144.048-1.544.583-3.113 1.188s-4.98 1.918-7.586 2.92l-9.24 3.55c-2.476.95-6.317 2.426-8.53 3.277s-4.15 1.612-4.308 1.69c-1.1.553-1.453 1.232-.943 1.793a1.99 1.99 0 0 0 .533.387c.17.078 2.192.724 4.493 1.436s4.2 1.308 4.21 1.326.738 2.345 1.6 5.17c1.033 3.375 1.616 5.182 1.7 5.263a1.515 1.515 0 0 0 1.123.18c.667-.11.433.086 3.805-3.185a9.087 9.087 0 0 1 1.592-1.414c.04.032 1.488 1.1 3.22 2.368l4.136 3.04a3.96 3.96 0 0 0 2.107 1.04 1.583 1.583 0 0 0 1.6-1.228c.087-.25 4.552-21.04 5.573-25.95.298-1.428.095-2.3-.632-2.725z"/>
                        </svg>
                    </a>
                </li>
                <li>
                    <a href="https://github.com/staddy">
                        <svg
                            width={props.iconWidth}
                            height={props.iconHeight}
                            viewBox="0 0 64 64"
                            xmlns="http://www.w3.org/2000/svg">
                            <title>Github</title>
                            <path data-name="layer2"
                                  d="M32 0a32.021 32.021 0 0 0-10.1 62.4c1.6.3 2.2-.7 2.2-1.5v-6c-8.9 1.9-10.8-3.8-10.8-3.8-1.5-3.7-3.6-4.7-3.6-4.7-2.9-2 .2-1.9.2-1.9 3.2.2 4.9 3.3 4.9 3.3 2.9 4.9 7.5 3.5 9.3 2.7a6.93 6.93 0 0 1 2-4.3c-7.1-.8-14.6-3.6-14.6-15.8a12.27 12.27 0 0 1 3.3-8.6 11.965 11.965 0 0 1 .3-8.5s2.7-.9 8.8 3.3a30.873 30.873 0 0 1 8-1.1 30.292 30.292 0 0 1 8 1.1c6.1-4.1 8.8-3.3 8.8-3.3a11.965 11.965 0 0 1 .3 8.5 12.1 12.1 0 0 1 3.3 8.6c0 12.3-7.5 15-14.6 15.8a7.746 7.746 0 0 1 2.2 5.9v8.8c0 .9.6 1.8 2.2 1.5A32.021 32.021 0 0 0 32 0z"/>
                            <path data-name="layer1"
                                  d="M12.1 45.9c-.1.2-.3.2-.5.1s-.4-.3-.3-.5.3-.2.6-.1c.2.2.3.4.2.5zm1.3 1.5a.589.589 0 0 1-.8-.8.631.631 0 0 1 .7.1.494.494 0 0 1 .1.7zm1.3 1.8a.585.585 0 0 1-.7-.3.6.6 0 0 1 0-.8.585.585 0 0 1 .7.3c.2.3.2.7 0 .8zm1.7 1.8c-.2.2-.5.1-.8-.1-.3-.3-.4-.6-.2-.8a.619.619 0 0 1 .8.1.554.554 0 0 1 .2.8zm2.4 1c-.1.3-.4.4-.8.3s-.6-.4-.5-.7.4-.4.8-.3c.3.2.6.5.5.7zm2.6.2c0 .3-.3.5-.7.5s-.7-.2-.7-.5.3-.5.7-.5c.4.1.7.3.7.5zm2.4-.4q0 .45-.6.6a.691.691 0 0 1-.8-.3q0-.45.6-.6c.5-.1.8.1.8.3z"/>
                        </svg>
                    </a>
                </li>
                <li>
                    <a href="https://vk.com/staddy">
                        <svg
                            width={props.iconWidth}
                            height={props.iconHeight}
                            viewBox="0 0 48 48"
                            xmlns="http://www.w3.org/2000/svg">
                            <title>VK</title>
                            <path
                                d="M22.647 35.647h2.4a2.027 2.027 0 0 0 1.095-.48 1.76 1.76 0 0 0 .33-1.054s-.047-3.224 1.447-3.7c1.472-.468 3.363 3.116 5.368 4.494a3.8 3.8 0 0 0 2.668.814l5.36-.074s2.8-.173 1.474-2.38c-.11-.18-.775-1.634-3.986-4.62-3.36-3.123-2.91-2.618 1.138-8.022 2.465-3.29 3.45-5.3 3.143-6.16-.293-.82-2.107-.6-2.107-.6l-6.035.036a1.368 1.368 0 0 0-.78.138 1.685 1.685 0 0 0-.532.648A35.085 35.085 0 0 1 31.4 19.4c-2.687 4.57-3.762 4.812-4.2 4.528-1.022-.66-.767-2.657-.767-4.075 0-4.43.67-6.276-1.306-6.754a10.3 10.3 0 0 0-2.817-.28c-2.154-.023-3.976.005-5.008.512-.687.337-1.216 1.087-.893 1.13a2.707 2.707 0 0 1 1.78.9 5.84 5.84 0 0 1 .6 2.735s.355 5.214-.83 5.86c-.813.445-1.93-.462-4.325-4.608a38.6 38.6 0 0 1-2.154-4.473 1.8 1.8 0 0 0-.5-.674 2.49 2.49 0 0 0-.926-.375l-5.735.038a1.954 1.954 0 0 0-1.178.4 1.253 1.253 0 0 0-.022 1.023S7.607 25.8 12.69 31.1a13.76 13.76 0 0 0 9.956 4.542z"/>
                        </svg>
                    </a>
                </li>
                <li>
                    <a href="https://twitter.com/prstad">
                        <svg
                            width={props.iconWidth}
                            height={props.iconHeight}
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg">
                            <title>Twitter</title>
                            <path
                                d="M16 3c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4C.7 7.7 1.8 9 3.3 9.3c-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4C15 4.3 15.6 3.7 16 3z"/>
                        </svg>
                    </a>
                </li>
                <li>
                    <a href="https://www.instagram.com/prstad">
                        <svg
                            width={props.iconWidth}
                            height={props.iconHeight}
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg">
                            <title>Instagram</title>
                            <g>
                                <circle cx="12.145" cy="3.892" r="1"/>
                                <path
                                    d="M8 12c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z"/>
                                <path
                                    d="M12 16H4c-2.056 0-4-1.944-4-4V4c0-2.056 1.944-4 4-4h8c2.056 0 4 1.944 4 4v8c0 2.056-1.944 4-4 4zM4 2c-.935 0-2 1.065-2 2v8c0 .953 1.047 2 2 2h8c.935 0 2-1.065 2-2V4c0-.935-1.065-2-2-2H4z"/>
                            </g>
                        </svg>
                    </a>
                </li>
            </ul>
        </div>
    );
}

FooterSocial.defaultProps = defaultProps;

export default FooterSocial;