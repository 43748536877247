import React from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import Image from '../../elements/Image';
import img from './../../../assets/images/icon32.png';

const defaultProps = {
    width: 32,
    height: 32
};

const Logo = ({
                  className,
                  ...props
              }) => {

    const classes = classNames(
        'brand',
        className
    );

    return (
        <div
            {...props}
            className={classes}
        >
            <h1 className="m-0">
                <Link to="/">
                    <Image
                        src={img}
                        alt="Open"
                        width={props.width}
                        height={props.height}/>
                </Link>
            </h1>
        </div>
    );
}

Logo.defaultProps = defaultProps;

export default Logo;